<template>
    <div class="main-view">
        <div style="margin-left: 0;text-align: left;display: flex;align-items: center">
            <van-icon @click="back" name="arrow-left" size="20"/>
            <span style="margin-left: 20px;">礼包领取</span>
        </div>
        <div class="game-info">
            <img :src="imgUrl" style="width: 65px">
            <div style="display: flex;flex-direction: column;align-items: flex-start;justify-content: space-around;margin-left: 20px">
                <span style="font-size: 16px;font-weight: 700">{{ gameName }}</span>
                <div style="font-size: 14px;">
                    <span class="game-tag" v-for="tag in tagList">{{tag}}</span>
                </div>
            </div>
        </div>

        <div class="game-menu">
            <span style="font-size: 16px;font-weight: 700;margin: 15px 15px 8px;">我的角色</span>

            <div class="no-item-view" v-if="rechargeInfoList.length === 0">
                <img src="../static/gift/no_item.png" style="width: 120px;height: 90px;">
                <span class="none-text" >暂无游戏角色</span>
            </div>

            <div v-for="(item,index) in rechargeInfoList" style="width: 100%">
                <div class="role-view" @click="openRecharge(index)">
                    <div style="display: flex;align-items: center">
                        <img src="../static/game_tc.png" style="width: 20px">
                        <span style="font-size: 14px;margin-left: 10px;" :style="item.selected ? 'font-weight: 700;color: #2979FF;' : ''">{{item.serverName}} / {{item.roleName}}</span>
                    </div>
                    <div style="display: flex;align-items: center">
                        <span  @click.stop="setRole(index)" v-show="!item.selected" style="font-size: 12px;padding: 2px 5px; color: #2979FF;border: 1px solid #2979FF;border-radius: 2px;">选择</span>
                        <span  v-show="item.selected" style="font-size: 12px;color: #E43D33;font-weight: bold">已选择</span>
<!--                        <van-icon v-show="!item.open" name="arrow-down" color="#888888" style="margin-left: 15px;"/>-->
<!--                        <van-icon v-show="item.open" name="arrow-up" color="#888888" style="margin-left: 15px;"/>-->
                    </div>
                </div>
                <div v-if="false">
                    <div  class="recharge-info">
                        <span>累计充值</span>
                        <span style="font-weight: bold">￥{{item.allAmount}}</span>
                    </div>
                    <div v-for="day in item.roleRechargeListList" class="recharge-info">
                        <span>{{day.rechargeDate}}</span>
                        <span style="font-weight: bold">￥{{day.rechargeAmount}}</span>
                    </div>
                </div>
                <div class="line"></div>
            </div>
        </div>

        <div style="background-color: #FFFAEF;font-size: 12px;color: #666666;text-align: left;margin-top: 15px;padding: 5px 3px;">
            <van-icon name="info-o" color="#FBB610"/>
            <span style="margin-left: 3px;color:#FBB610;">提示：领取礼包之前请先选择需要领取的角色</span>
        </div>

        <div class="game-menu" style="margin-top: 10px;">
            <div @click="toRecordPage" style="display: flex;align-items: center;justify-content: space-between;padding: 12px 0;width: 90%;margin: 0 auto;font-size: 14px;">
                <span>礼包领取记录</span>
                <van-icon  name="arrow" color="#888888" />
            </div>
        </div>
        <div class="game-menu" style="margin-top: 5px;">
            <div @click="openDateSelector" style="display: flex;align-items: center;justify-content: space-between;padding: 12px 0;width: 90%;margin: 0 auto;font-size: 14px;">
                <span>选择充值日期</span>
                <div style="display: flex;align-items: center;">
                    <span style="margin-right: 15px;">{{selectedDate}}</span>
                    <van-icon  name="arrow" color="#888888" />
                </div>

            </div>
        </div>

        <van-popup v-model="showDateSelector" position="bottom" >
            <van-picker
                    title="请选择充值日期"
                    show-toolbar
                    :columns="selectDateList"
                    @confirm="onConfirmDate"
                    @cancel="showDateSelector = false"
            />
        </van-popup>

        <div style="margin-top: 13px;">
            <div class="game-menu"  v-for="(item,index) in list" style="margin-top: 2px;">
                <div @click="open(index)" style="display: flex;align-items: center;justify-content: space-between;padding: 10px 0;width: 90%;margin: 0 auto;font-size: 14px;">
                    <span style="font-size: 17px;font-weight: bold;color: #586EFC;">{{item.giftName}}</span>
                    <div style="display: flex;align-items: center">
                        <van-button v-if="item.status===0" @click.stop="openRoleIdPopup(item.giftId)" size="mini" round color="#3DA7FF" class="get-button"><span class="get-button-text">领取</span></van-button>
                        <van-button v-if="item.status===3" size="mini" round color="#DDDDDD" class="get-button"><span class="get-button-text">驳回</span></van-button>
                        <van-button v-if="item.status===4" size="mini" round color="#DDDDDD" class="get-button"><span class="get-button-text">同组已领</span></van-button>
                        <van-button v-if="item.status===-1" size="mini" round color="#DDDDDD" class="get-button"><span class="get-button-text">不可领取</span></van-button>
                        <van-button v-if="item.status===2" size="mini" round color="#DDDDDD" class="get-button"><span class="get-button-text">待审核</span></van-button>
                        <van-button v-if="item.status===1" size="mini" round color="#DDDDDD" class="get-button"><span class="get-button-text">已发放</span></van-button>
                        <van-icon v-show="!item.open" name="arrow-down" color="#888888" />
                        <van-icon v-show="item.open" name="arrow-up" color="#888888" />
                    </div>
                </div>
                <div v-show="item.open" style="width: 85%;margin: 10px auto;text-align: left;font-size: 12px;color: #666666">
                    <div class="game-detail-info">
                        <span style="color: #6C69FF;font-size: 13px;">领取条件</span>
                    </div>
                    <span style="margin: 20px 10px 0;display: block">
                        {{item.giftCondition || '无'}}
                    </span>
                        <div class="game-detail-info" style="margin-top: 15px;">
                            <span style="color: #6C69FF;font-size: 13px;">礼包内容</span>
                        </div>
                        <span style="margin: 20px 10px 0;display: block">
                        {{item.giftContent || '无'}}
                    </span>
                        <div class="game-detail-info" style="margin-top: 15px;">
                            <span style="color: #6C69FF;font-size: 13px;">礼包说明</span>
                        </div>
                        <span style="margin: 20px 10px 0;display: block;">
                        {{item.giftExplain || '无'}}
                    </span>
                </div>
            </div>
        </div>

        <myloading v-show="showLoading"></myloading>

        <van-dialog v-model="showRoleInfoDialog" show-cancel-button title="请确认您的礼包领取信息" @confirm="doGetGift">
            <div style="font-size: 14px;padding: 15px 25px;">
                <van-field class="common-input" type="text" label="QQ号码" label-width="58px" v-model="roleInfoQQ" clearable placeholder="请输入您的QQ号码"/>
                <van-field class="common-input" type="text" label="微信" label-width="58px" v-model="roleInfoWx" clearable placeholder="请输入您的微信号码"/>
                <van-field class="common-input" type="text" label="角色ID" label-width="58px" v-model="roleInfoId" clearable placeholder="请输入您的角色ID"/>
                <!--<input placeholder="请输入验证码" type="number" v-model="unbindCode" style="border: 1px solid #EEEEEE;padding: 8px;font-size: 14px;margin-left: 10px;"/>-->
            </div>
        </van-dialog>
    </div>
</template>

<script>
    import {request} from '@/api/request'
    import {Toast,Dialog} from 'vant'
    import myloading from '@/components/myloading'

    export default {
        name: "giftDetail",
        components: {
            myloading
        },
        data() {
            return {
                showLoading: false,
                gameId: '',
                imgUrl: '',
                gameName: '',
                list: [],
                tagList: [],
                rechargeInfoList: [],
                selectRole: {},
                selectedGiftId: '',
                showRoleInfoDialog: false,
                roleInfoQQ: '',
                roleInfoWx: '',
                roleInfoId: '',
                showDateSelector: false,
                selectDateList: [],
                selectedDate: '未选择',
                selectedIndex: -1,
                isApp: '',
            }
        },
        mounted() {
            this.isApp = localStorage.getItem('isApp');
        },
        methods: {
            back() {
                this.$parent.$parent.closeGiftDetailPage();
            },
            init() {
                this.gameId = JSON.parse(localStorage.getItem('systemInfo')).gameId;
                this.getData();
            },
            toRecordPage() {
                this.$parent.$parent.toGiftRecordPage();
            },
            getData() {
                this.showLoading = true;
                let options = {
                    url:"/zfapp/getGameGiftList",
                    data: {}
                };
                request(options).then(res => {
                    this.showLoading = false;
                    if (res.data.success) {
                        let d = res.data.data;
                        this.imgUrl = d.gameImgUrl;
                        this.gameName = d.gameName;
                        this.tagList = d.tagList;
                        d.giftList.forEach(function(e,v) {
                            e.open = false;
                        });
                        this.list = d.giftList;
                        if (d.rechargeInfoList) {
                            d.rechargeInfoList.forEach(function(e,v) {
                                e.open = false;
                                e.selected = false;
                            });
                            this.rechargeInfoList = d.rechargeInfoList;
                        }

                    }
                    else {
                        Toast(res.data.error.message);
                    }
                });
            },
            openRecharge(index){
                this.rechargeInfoList[index].open = !this.rechargeInfoList[index].open;
            } ,
            setRole(index) {
                this.rechargeInfoList.forEach(function(e,v) {
                    e.selected = false;
                });
                this.selectRole = this.rechargeInfoList[index];
                this.rechargeInfoList[index].selected = !this.rechargeInfoList[index].selected;

                this.selectDateList = [];
                let me = this;
                this.rechargeInfoList[index].roleRechargeListList.forEach(function(e,v) {
                    me.selectDateList.push(e.rechargeDate);
                });
                this.selectedIndex = -1;
                this.selectedDate = '未选择';

                this.refreshGiftList('角色选择成功');
            },
            refreshGiftList(title) {
                this.showLoading = true;
                let date_ = this.selectedDate === '未选择' ? '' : this.selectedDate;
                let options = {
                    url:"/zfapp/getGameGiftStatus",
                    data: {
                        gameId: this.gameId,
                        roleCode: this.selectRole.orderRoleCode,
                        roleId: this.selectRole.roleId,
                        giftDate: date_,
                    }
                };
                request(options).then(res => {
                    this.showLoading = false;
                    if (res.data.success) {
                        let d = res.data.data;
                        this.list.forEach(function(e,v) {
                            for(let i = 0; i < d.length ; i++) {
                                if (e.giftId === d[i].giftId) {
                                    e.status = d[i].status
                                    break;
                                }
                            }
                        });
                        if (title) {
                            Toast(title);
                        }
                    }
                    else {
                        Toast(res.data.error.message);
                    }
                });
            },
            open(index) {
                this.list[index].open = !this.list[index].open;
            },
            openRoleIdPopup(giftId) {
                this.selectedGiftId = giftId;
                this.showLoading = true;
                let options = {
                    url:"/zfapp/getUserReportRoleId",
                    data: {
                        roleId: this.selectRole.roleId
                    }
                };
                request(options).then(res => {
                    this.showLoading = false;
                    if (res.data.success) {
                        let d = res.data.data;
                        this.roleInfoQQ = d.qq;
                        this.roleInfoWx = d.wx;
                        this.roleInfoId = d.userReportRole;
                        this.showRoleInfoDialog = true;
                    }
                    else {
                        Toast(res.data.error.message);
                    }
                });

            },
            doGetGift() {
                this.showLoading = true;
                let date_ = this.selectedDate === '未选择' ? '' : this.selectedDate;
                let options = {
                    url:"/zfapp/getGift",
                    data: {
                        gameId: this.gameId,
                        roleCode: this.selectRole.orderRoleCode,
                        roleId: this.selectRole.roleId,
                        giftId: this.selectedGiftId,
                        giftDate: date_,
                        qq: this.roleInfoQQ,
                        wx: this.roleInfoWx,
                        userReportRole: this.roleInfoId
                    }
                };
                request(options).then(res => {
                    this.showLoading = false;
                    if (res.data.success) {
                        let d = res.data.data;
                        let me = this;
                        if (d.code === 1) {
                            this.refreshGiftList();
                            this.copyCode(d.msg);
                        }
                        else {
                            Toast('礼包申请成功，请等待客服审核');
                            this.refreshGiftList();
                        }
                    }
                    else {
                        Toast(res.data.error.message);
                    }
                });
            },
            copyCode(v) {
                Dialog.alert({
                    title: '礼包码',
                    message: '你的礼包码为' + v,
                    showCancelButton: false,
                    confirmButtonText: '复制'
                }).then(() => {
                    if (this.isApp === '1') {
                        uni.postMessage({
                            data: {
                                action: 'uniCopy',
                                msg: v
                            }
                        });
                    }
                    else {
                        const input = document.createElement('input')
                        document.body.appendChild(input)
                        input.setAttribute('value', v)
                        input.select()
                        if (document.execCommand('copy')) {
                            document.execCommand('copy')
                        }
                        document.body.removeChild(input)
                    }
                })
            },
            onConfirmDate(value, index) {
                if (this.selectDateList.length === 0) {
                    Toast('当前没有帐号可以选择');
                    return ;
                }
                this.selectedDate = value;
                this.showDateSelector = false;
                this.refreshGiftList('日期选择成功');
            },
            openDateSelector() {
                this.showDateSelector = true;
            },
        }
    }
</script>

<style scoped>
    .main-view {
        width: 100vw;
        height: 100%;
        display: flex;
        flex-direction: column;
        background-color: #F5F6F8;
        overflow: hidden;
        overflow-y: auto;
        box-sizing: border-box;
        padding: 60px 20px 10px;
    }
    .game-info {
        display: flex;
        background-color: #FFFFFF;
        padding: 10px;
        border-radius: 5px;
        margin-top: 20px;
    }
    .game-tag {
        font-size: 12px;
        border-radius: 2px;
        margin-right: 5px;
        background-color: #F0F3FE;
        padding: 2px 5px;
        color: #6185F1
    }
    .game-menu{
        display: flex;
        flex-direction: column;
        background-color: #FFFFFF;
        border-radius: 5px;
        margin-top: 5px;
        align-items: flex-start;
    }
    .no-item-view{
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding-bottom: 15px;
    }
    .none-text{
        font-size: 14px;
        color: #C7C8FE;
        text-align: center;
    }
    .role-view{
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        padding: 15px 0;
        width: 90%;
        margin: 0 auto;
    }
    .line{
        width: 90%;
        height: 0.5px;
        margin: 0 auto;
        background-color: #EEEEEE;
    }
    .game-detail-info{
        background-image: url(../static/gift/title_background.png);
        background-repeat: no-repeat;
        background-size: 100% 100%;
        width: 60px;
        height: 10px;
        padding-top: 5px;
        text-align: center;
    }
    .recharge-info {
        font-size: 13px;
        color: #999999;
        padding: 0 30px;
        margin-bottom: 12px;
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
    .get-button {
        padding: 0 15px;
        margin-right: 15px;
    }
    .get-button-text {
        font-size: 14px;
        font-weight: bold
    }
    .van-field{
        outline: none;
        border-bottom: 1px #dbdbdb solid !important;
        border-radius: 3px;
    }
</style>